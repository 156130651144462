body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    background-color: #8E7C93;
  }
    .container {
    background-color: #FFF;
    margin: 100px;
    padding: 10px;
    border-radius: 3px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
    .buttons {
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 3px;
    transition: all .15s ease-in;
  }
  .buttons:hover:nth-child(1) {

    background-color: #F45B69;
}
  .buttons:hover:nth-child(3) {
  background-color: #C0DFA1;
}
